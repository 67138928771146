.App {
  text-align: center;
  background-color: #f5f2eb;
  height: 100vh;
  font-family: "Work Sans";
  display: grid;
  align-content: space-between;
}

.App__topinfo {
  background-color: #fefdfa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  padding: 18px;
  color: #797467;
  margin: auto;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.App__main {
  margin: -30px 20px 0 20px;
}

.App__main_content {
  display: inline-block;
  text-align: left;
}

.App__footer {
  padding-bottom: 60px;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
